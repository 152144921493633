// src/Search.js
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, Link } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import TripCard from './components/TripCard';
import './styles/Search.css';
import { getUpcomingWeekends } from './utilities/dateHelpers';
import { AuthContext } from './AuthContext';
import datepicker from 'js-datepicker';
import 'js-datepicker/dist/datepicker.min.css';
import { fetchFlights } from './utilities/fetchFlights';

const Search = () => {
  const [searchParams, setSearchParams] = useState({
    departureAirport: '',
    departureDate: '',
    anyWeekend: false,
  });
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [displayCount, setDisplayCount] = useState(10);
  const location = useLocation();
  const { currentUser } = useContext(AuthContext);
  const dateInputRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [warningMessage, setWarningMessage] = useState(null);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [tripType, setTripType] = useState('dayTrip');

  useEffect(() => {
    if (dateInputRef.current) {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);

      const picker = datepicker(dateInputRef.current, {
        onSelect: (instance, date) => {
          const timezoneOffset = date.getTimezoneOffset() * 60000;
          const localAdjustedDate = new Date(date.getTime() - timezoneOffset);
          const formattedDate = localAdjustedDate.toISOString().split('T')[0];
          setSearchParams((currentParams) => ({ ...currentParams, departureDate: formattedDate }));
        },
        minDate: tomorrow,
      });

      return () => {
        if (picker) {
          picker.remove();
        }
      };
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const departureAirport = queryParams.get('departureAirport');
    if (departureAirport) {
      setSearchParams((params) => ({ ...params, departureAirport }));
    }
  }, [location]);

  useEffect(() => {
    let intervalId;
    if (isLoading) {
      setProgress(0);
      const startTime = Date.now();
      intervalId = setInterval(() => {
        const elapsed = Date.now() - startTime;
        const percentage = Math.min((elapsed / 13000) * 100, 100); // 10 seconds
        setProgress(percentage);
        if (percentage >= 100) {
          clearInterval(intervalId);
        }
      }, 100); // Update every 100ms for smoother animation
    } else {
      setProgress(0);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isLoading]);

  const loadMoreResults = () => {
    setDisplayCount((prevCount) => prevCount + 10);
  };

  const performSearch = async (dates) => {
    setIsLoading(true);
    let results = [];
    for (const departureDate of dates) {
      let returnDate = departureDate;
      if (tripType === 'oneNightTrip') {
        const departureDateObj = new Date(departureDate);
        departureDateObj.setDate(departureDateObj.getDate() + 1);
        returnDate = departureDateObj.toISOString().split('T')[0];
      }

      let fetchParams = {
        departureAirport: searchParams.departureAirport,
        departureDate: departureDate,
        returnDate: returnDate,
      };

      if (tripType === 'oneNightTrip') {
        Object.assign(fetchParams, {
          dtimeFrom: '04:00',
          dtimeTo: '17:00',
          retDtimeFrom: '06:00',
          retDtimeTo: '23:00',
          nightsInDstFrom: '1',
          nightsInDstTo: '2',
        });
      }

      const flights = await fetchFlights(
        fetchParams,
        true,
        setIsLoading,
        setError,
        setSearchResults,
        setErrorMessage,
        searchPerformed,
        setWarningMessage
      );
      results = results.concat(flights);
    }
    setSearchResults(results.sort((a, b) => a.price - b.price));
    setIsLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSearchPerformed(true);
    setErrorMessage(null);
    setWarningMessage(null);
    setSearchResults([]); // Clear previous results

    if (searchParams.departureAirport) {
      if (searchParams.anyWeekend) {
        const upcomingWeekends = getUpcomingWeekends();
        await performSearch(upcomingWeekends);
      } else {
        await performSearch([searchParams.departureDate]);
      }
    }
  };

  const handleAirportChange = (e) => {
    setSearchParams((prevState) => ({ ...prevState, departureAirport: e.target.value }));
  };

  const handleDateChange = (e) => {
    setSearchParams({ ...searchParams, departureDate: e.target.value });
  };

  const handleToggleChange = () => {
    const newTripType = tripType === 'dayTrip' ? 'oneNightTrip' : 'dayTrip';
    setTripType(newTripType);
  };

  return (
    <div className="search-page">
      <Helmet>
        <title>Search for an Extreme Day Trip</title>
        {/* ... (other meta tags) */}
      </Helmet>
      <Navbar />
      {searchPerformed && errorMessage && <div className="error-banner">{errorMessage}</div>}
      {searchPerformed && warningMessage && <div className="warning-banner">{warningMessage}</div>}
      <div className="search-container">
        <form className="search-form" onSubmit={handleSubmit} autoComplete="off">
          <div className="search-field">
            <label htmlFor="departureAirport">Departure Airport</label>
            <select
              id="departureAirport"
              name="departureAirport"
              value={searchParams.departureAirport}
              onChange={handleAirportChange}
              required
              disabled={isLoading}
            >
              {/* Airport options */}
              <option value="">Select an airport</option>
              <option value="LON">All London Airports (LON)</option>
              <option value="LHR">London Heathrow (LHR)</option>
              <option value="LGW">London Gatwick (LGW)</option>
              <option value="LCY">London City Airport (LCY)</option>
              <option value="LTN">London Luton (LTN)</option>
              <option value="STN">London Stansted (STN)</option>
              <option value="SEN">London Southend (SEN)</option>
              <option value="BHX">Birmingham (BHX)</option>
              <option value="MAN">Manchester (MAN)</option>
              <option value="GLA">Glasgow (GLA)</option>
              <option value="EDI">Edinburgh (EDI)</option>
              <option value="BRS">Bristol (BRS)</option>
              <option value="LBA">Leeds (LBA)</option>
              <option value="LPL">Liverpool (LPL)</option>
              <option value="NCL">Newcastle (NCL)</option>
              <option value="BFS">Belfast International (BFS)</option>
              <option value="BHD">Belfast City (BHD)</option>
              <option value="BOH">Bournemouth (BOH)</option>
              <option value="EMA">East Midlands (EMA)</option>
              <option value="ABZ">Aberdeen (ABZ)</option>
              <option value="SOU">Southampton (SOU)</option>
              <option value="CWL">Cardiff (CWL)</option>
              <option value="JER">Jersey (JER)</option>
              <option value="GCI">Guernsey (GCI)</option>
              <option value="INV">Inverness (INV)</option>
              <option value="EXT">Exeter (EXT)</option>
              <option value="NWI">Norwich (NWI)</option>
              <option value="DND">Dundee (DND)</option>
              <option value="IOM">Isle of Man (IOM)</option>
              <option value="DUB">Dublin (DUB)</option>
              <option value="ORK">Cork (ORK)</option>
            </select>
          </div>
          <div className="search-field">
            <label htmlFor="departureDate">Departure Date</label>
            <input
              type="text"
              id="departureDate"
              name="departureDate"
              ref={dateInputRef}
              value={searchParams.departureDate}
              onChange={handleDateChange}
              disabled={isLoading || searchParams.anyWeekend}
              required={!searchParams.anyWeekend}
            />
          </div>
          <div className="search-field search-field-toggle">
            <div className="toggle-container">
              <span>Day Trip</span>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={tripType === 'oneNightTrip'}
                  onChange={handleToggleChange}
                />
                <span className="slider round"></span>
              </label>
              <span>One Night Trip</span>
            </div>
          </div>
          <button type="submit" className="search-button" disabled={isLoading}>
            Search Flights
          </button>
        </form>
      </div>
      <div className="search-results">
        {isLoading ? (
          <div className="loader-container">
            <div className="progress-bar-container">
              <div
                className="progress-bar-fill"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
            <p className="progress-text">Loading {Math.round(progress)}%</p>
          </div>
        ) : error ? (
          <p>Error: {error}</p>
        ) : (
          searchResults.slice(0, displayCount).map((flight, index) => (
            <TripCard key={index} tripData={flight} />
          ))
        )}
        {!isLoading && displayCount < searchResults.length && (
          currentUser ? (
            <button onClick={loadMoreResults} className="load-more-button">
              Load More
            </button>
          ) : (
            <div>
              <button className="load-more-button disabled" disabled>
                Load More
              </button>
              <p className="sign-in-prompt">
                To view more than 10 trips,
                <Link to="/Welcome" className="sign-in-link"> create a free account</Link>.
              </p>
            </div>
          )
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Search;