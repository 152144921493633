// src/FAQ.js
import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './styles/faq.css';
import { Helmet } from 'react-helmet';

const FAQ = () => {
   return (
    <div className="faq-container">
      <Helmet>
        <title>FAQs - Extreme Day Trips</title>
        <meta name="description" content="Find answers to frequently asked questions about Extreme Day Trips. Learn about our services, booking process, safety measures, and more." />
        <meta name="keywords" content="FAQ, Extreme Day Trips, adventure travel, booking, safety, questions" />
        <meta property="og:title" content="FAQs - Extreme Day Trips" />
        <meta property="og:description" content="Get answers to your queries on adventure travel with Extreme Day Trips. Discover how to book, customize your trips, and understand our safety protocols." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://extremedaytrips.com/faq" />
        <meta property="og:image" content="https://extremedaytrips.com/path-to-your-og-image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="FAQs - Extreme Day Trips" />
        <meta name="twitter:description" content="Answers to common questions about Extreme Day Trips, covering our services, booking, safety, and more. Everything you need to know for your next adventure." />
        <meta name="twitter:image" content="https://extremedaytrips.com/path-to-your-twitter-card-image.jpg" />
        {/* Add any additional SEO related tags as needed */}
      </Helmet>
      <Navbar />
      <header className="faq-hero">
        <h1>FAQs</h1>
        <p>Have questions? Here you'll find the answers most valued by our partners, along with access to step-by-step instructions and support.</p>
      </header>
      <div className="faq-content">
        <aside className="faq-links">
          <ul>
            <li><a href="#about-us">About us</a></li>
            <li><a href="#how-it-works">How it works</a></li>
            <li><a href="#safety">Safety</a></li>
            <li><a href="#booking">Booking</a></li>
          </ul>
        </aside>
        <main className="faq-questions">
          <section id="about-us">
            <h2>About Us</h2>
            <details>
              <summary>Who are we?</summary>
              <p>Extreme Day Trips specializes in crafting unforgettable experiences for thrill-seekers and adventure enthusiasts, providing a unique selection of day trips to the most exhilarating destinations.</p>
            </details>
            <details>
              <summary>What makes Extreme Day Trips unique?</summary>
              <p>Our focus on day-long adventures allows travelers to experience the thrill of adventure sports and unique experiences without the need for extended time commitments.</p>
            </details>
          </section>
          <section id="how-it-works">
            <h2>How it works</h2>
            <details>
              <summary>How do I book a trip?</summary>
              <p>Booking is simple: find your desired trip on our platform, select the date that suits you, and complete the booking process with our secure online payment system.</p>
            </details>
            <details>
              <summary>Can I customize my trip?</summary>
              <p>Yes, we offer customizable options to tailor your day trip experience to your preferences. Please contact us for more details.</p>
            </details>
          </section>
          <section id="safety">
            <h2>Safety</h2>
            <details>
              <summary>How do you ensure safety on your trips?</summary>
              <p>We adhere to strict safety protocols, including vetting all providers for compliance with local safety regulations, and providing all participants with safety briefings and equipment.</p>
            </details>
            <details>
              <summary>What should I bring for my safety?</summary>
              <p>We recommend you bring appropriate clothing for the activity, any personal medications, and leave valuables at home.</p>
            </details>
          </section>
          <section id="booking">
            <h2>Booking</h2>
            <details>
              <summary>What is the cancellation policy?</summary>
              <p>Refunds subject to your airline policy</p>
            </details>
            <details>
              <summary>What happens if the trip is canceled by Extreme Day Trips?</summary>
              <p>Extreme day trips just helps you to find trips, it does not actually provide them. This will be subject to your airline.</p>
            </details>
          </section>
          {/* Add more sections as needed */}
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default FAQ;
