// src/SubmitTrip.js
import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { collection, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { db } from './firebaseConfig';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './styles/SubmitTrip.css';
import useAirportPicker from './utilities/airportpicker';

const SubmitTrip = () => {
  const [tripData, setTripData] = useState({
    departureAirport: '',
    arrivalAirport: '',
    destinationCity: '',
    price: '',
    departureDateTime: '',
    flightNumber: ''
  });
  const [airports, setAirports] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const departureWrapperRef = useRef(null);
  const arrivalWrapperRef = useRef(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();

  // Ref for the wrapper to detect outside clicks
  const wrapperRef = React.useRef(null);

  useEffect(() => {
      // Event listener to handle clicks outside of the component
      function handleClickOutside(event) {
        if (departureWrapperRef.current && !departureWrapperRef.current.contains(event.target)) {
          setDepartureSuggestions([]); // Hide departure suggestions
        }
        if (arrivalWrapperRef.current && !arrivalWrapperRef.current.contains(event.target)) {
          setArrivalSuggestions([]); // Hide arrival suggestions
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
  }, []);

  useEffect(() => {
    // Listen for auth state changes
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth]);

  const handleRedirect = () => {
    navigate('/welcome');
  };

  const {
    handleAutocomplete,
    departureSuggestions,
    setDepartureSuggestions,
    arrivalSuggestions,
    setArrivalSuggestions,
  } = useAirportPicker();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTripData(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (name === 'departureAirport') {
      handleAutocomplete(value, 'departureAirport');
    } else if (name === 'arrivalAirport') {
      handleAutocomplete(value, 'arrivalAirport');
    } else {
      // For any other field, ensure no suggestions are set
      setDepartureSuggestions([]);
      setArrivalSuggestions([]);
    }
  };


  const handleSuggestionClick = (suggestion, field) => {
    if (field === 'arrivalAirport') {
      setTripData(prevState => ({
        ...prevState,
        arrivalAirport: suggestion.iata_code,
        destinationCity: suggestion.city
      }));
    } else if (field === 'departureAirport') {
      setTripData(prevState => ({
        ...prevState,
        departureAirport: suggestion.iata_code
      }));
    }
    setDepartureSuggestions([]);
    setArrivalSuggestions([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the user is not logged in
    if (!user) {
      setSuccessMessage('You must be signed in to submit a trip.');
      return;
    }

    try {
      await addDoc(collection(db, "trips"), {
        ...tripData,
        userUID: user.uid, // Include the user UID
        submittedAt: new Date(),
      });
      setSuccessMessage('Your trip has been successfully added!');
      setTripData({
        departureAirport: '',
        arrivalAirport: '',
        destinationCity: '',
        price: '',
        departureDateTime: '',
        flightNumber: ''
      });
    } catch (error) {
      console.error('Error submitting trip: ', error);
      setSuccessMessage('Failed to add the trip. Please try again.');
    }
  };

  const renderContent = () => {

    if (successMessage) {
      return " ";
    }

    if (user) {
      return (
        <form className="submit-trip-form" onSubmit={handleSubmit} autoComplete="off">

            <div className="autocomplete" ref={departureWrapperRef}>
              <label htmlFor="departureAirport">Departure Airport</label>
              <input type="text" id="departureAirport" name="departureAirport" value={tripData.departureAirport} onChange={handleChange} required />
              {departureSuggestions.length > 0 && (
                <ul className="suggestions">
                  {departureSuggestions.map((suggestion, index) => (
                    <li key={index} onClick={() => handleSuggestionClick(suggestion, 'departureAirport')}>
                      {suggestion.name} ({suggestion.iata_code})
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div className="autocomplete" ref={arrivalWrapperRef}>
              <label htmlFor="arrivalAirport">Arrival Airport</label>
              <input type="text" id="arrivalAirport" name="arrivalAirport" value={tripData.arrivalAirport} onChange={handleChange} required />
              {arrivalSuggestions.length > 0 && (
                <ul className="suggestions">
                  {arrivalSuggestions.map((suggestion, index) => (
                    <li key={index} onClick={() => handleSuggestionClick(suggestion, 'arrivalAirport')}>
                      {suggestion.name} ({suggestion.iata_code})
                    </li>
                  ))}
                </ul>
              )}
            </div>

          <label htmlFor="destinationCity">Destination City</label>
          <input type="text" id="destinationCity" name="destinationCity" value={tripData.destinationCity} onChange={handleChange} required />

          <label htmlFor="price">Price</label>
          <input type="number" id="price" name="price" value={tripData.price} onChange={handleChange} required />

          <label htmlFor="departureDateTime">Departure Date and Time</label>
          <input type="datetime-local" id="departureDateTime" name="departureDateTime" value={tripData.departureDateTime} onChange={handleChange} required />

          <label htmlFor="flightNumber">Flight Number</label>
          <input type="text" id="flightNumber" name="flightNumber" value={tripData.flightNumber} onChange={handleChange} required />

          <button type="submit" className="submit-btn">Submit Trip</button>
        </form>
      );
    } else {
      return (
        // Sign-in message
        <div className="signin-message">
          <p>You must be signed in to submit a trip.</p>
          <p>Create an account in two minutes to submit and view day trips.</p>
          <button onClick={handleRedirect}>Sign In / Sign Up</button>
        </div>
      );
    }
  };

  return (
    <div className="submit-trip-container">
    <Helmet>
      <title>Submit Your Trip - Extreme Day Trips</title>
      <meta name="description" content="Share your travel adventures with the Extreme Day Trips community. Submit your trip details and experiences here." />
      <meta name="keywords" content="submit trip, travel, adventure, day trips, Extreme Day Trips" />
      <meta property="og:title" content="Submit Your Trip - Extreme Day Trips" />
      <meta property="og:description" content="Join the Extreme Day Trips community by sharing your unique travel experiences. Submit your trip and inspire others." />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://extremedaytrips.com/submit-trip" />
      <meta property="og:image" content="https://extremedaytrips.com/path-to-your-og-image.jpg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Submit Your Trip - Extreme Day Trips" />
      <meta name="twitter:description" content="Share your travel journey with the Extreme Day Trips community. Submit your trip details and explore others' adventures." />
      <meta name="twitter:image" content="https://extremedaytrips.com/path-to-your-twitter-card-image.jpg" />
      {/* Add any additional SEO related tags as needed */}
    </Helmet>
      <Navbar />
      <header className="submit-trip-hero">
        <h1>Submit Your Trip</h1>
        <p>Share your adventure with our community.</p>
      </header>
      <div className="submit-trip-form-container" ref={wrapperRef}>
        {successMessage && <div className="success-message">{successMessage}</div>}
        {renderContent()}
      </div>
      <Footer />
    </div>
  );
};

export default SubmitTrip;
