// src/StepOne.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DOMParser } from 'xmldom';
import '../styles/style.css';

const StepOne = ({ nextStep, handleChange, values }) => {
  const [error, setError] = useState('');
  const [countryCodes, setCountryCodes] = useState([]);

  useEffect(() => {
    axios.get('/phoneCodes.xml') // Replace with the actual URL to your XML file
      .then(response => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(response.data, 'text/xml');
        const items = xmlDoc.getElementsByTagName('item');
        const codes = Array.from(items).map(item => {
          return {
            country: item.getElementsByTagName('country')[0].textContent,
            countryCode: item.getElementsByTagName('countryCode')[0].textContent
          };
        });
        setCountryCodes(codes);

        // Set default country code to +44 if not already set
        if (!values.countryCode) {
          handleChange('countryCode')({ target: { value: '+44' } });
        }
      })
      .catch(error => console.error('Error fetching country codes:', error));
  }, [handleChange, values.countryCode]);
  
  // Function to check if all fields are filled
  const handleNextClick = () => {
    if (!values.firstName || !values.lastName || !values.email || !values.password || !values.phone) {
      setError('Please fill out all fields.'); // Set error message
    } else {
      setError(''); // Clear error message
      nextStep();
    }
  };

  return (
    <div className="welcome-content">
      <h1>Sign Up - Step 1</h1>
      {error && <div className="error-message">{error}</div>} {/* Display error message if it exists */}
      <div className="flex-row">
        <input
          type="text"
          placeholder="First Name *"
          onChange={handleChange('firstName')}
          value={values.firstName}
          className="input-field half-width"
          required
        />
        <input
          type="text"
          placeholder="Last Name *"
          onChange={handleChange('lastName')}
          value={values.lastName}
          className="input-field half-width"
          required
        />
      </div>
      <input
        type="email"
        placeholder="Email Address *"
        onChange={handleChange('email')}
        value={values.email}
        className="input-field"
        required
      />
      <div className="flex-row">
        <select
          className="input-field country-code-dropdown"
          onChange={handleChange('countryCode')}
          value={values.countryCode}
          required
        >
          {countryCodes.map(({ country, countryCode }, index) => (
            <option key={index} value={`+${countryCode}`}>+{countryCode} - {country}</option>
          ))}
        </select>
        <input
          type="tel"
          placeholder="Phone Number *"
          onChange={handleChange('phone')}
          value={values.phone}
          className="input-field phone-input"
          required
        />
      </div>
      <input
        type="password"
        placeholder="Password *"
        onChange={handleChange('password')}
        value={values.password}
        className="input-field"
        required
      />
      <button onClick={handleNextClick} className="welcome-button">Next</button>
    </div>
  );
};


export default StepOne;
