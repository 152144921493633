// src/Home.js
import React, { useState, useRef, useEffect } from 'react';
import './styles/Home.css';
import Navbar from './components/Navbar'; // Ensure the path is correct
import Footer from './components/Footer'; // Ensure the path is correct
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMountain, faTicketAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { faPlane } from '@fortawesome/free-solid-svg-icons';

const Home = () => {

  const navigate = useNavigate();
  const carouselRef = useRef(null);
  const [email, setEmail] = useState('');
  const [selectedAirport, setSelectedAirport] = useState('');

  const navigateToSearch = () => {
    navigate('/search');
  };

  const navigateToSearchWithParams = (airportCode) => {
    navigate(`/search?departureAirport=${airportCode}`);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleAirportChange = (e) => {
    setSelectedAirport(e.target.value);
  };

 const carouselItems = [
    { id: 1, title: "All London Airports", code: "LON", bgColor: "#ffddd2" },
    { id: 2, title: "London Heathrow", code: "LHR", bgColor: "#d2eaff" },
    { id: 3, title: "London Gatwick", code: "LGW", bgColor: "#ffeca2" },
    { id: 4, title: "London Luton", code: "LTN", bgColor: "#c2f0c2" },
    { id: 5, title: "London Stansted", code: "STN", bgColor: "#f2d2ff" },
    { id: 6, title: "Birmingham", code: "BHX", bgColor: "#ffd2f0" },
    { id: 7, title: "Manchester", code: "MAN", bgColor: "#d2faff" },
    { id: 8, title: "Glasgow", code: "GLA", bgColor: "#f0d2c2" },
    { id: 9, title: "Edinburgh", code: "EDI", bgColor: "#d2ffd2" },
    { id: 10, title: "Bristol", code: "BRS", bgColor: "#d2ffd2" },
    { id: 11, title: "Newcastle", code: "NCL", bgColor: "#d2ffd2" }
  ];

 useEffect(() => {
    const interval = setInterval(() => {
      if (carouselRef.current) {
        const scrollLeft = carouselRef.current.scrollLeft;
        const scrollWidth = carouselRef.current.scrollWidth;
        const clientWidth = carouselRef.current.clientWidth;
        
        // Calculate the remaining scroll width
        const maxScrollLeft = scrollWidth - clientWidth;

        // Scroll by one card width (300px + 20px gap) or reset to start if near the end
        const newScrollPosition = (scrollLeft + 320) >= maxScrollLeft ? 0 : scrollLeft + 320;
        
        carouselRef.current.scrollTo({
          left: newScrollPosition,
          behavior: 'smooth',
        });
      }
    }, 3000); // Scroll every 3 seconds

    return () => clearInterval(interval); // Clear the interval on component unmount
  }, []);


  return (
    <div className="app-container">
      <Helmet>
        <title>Welcome to Extreme Day Trips</title>
        <meta name="description" content="Join Extreme Day Trips for unforgettable day-long adventures. Discover unique trips, enjoy easy booking, and connect with a community of explorers." />
        <meta name="keywords" content="Extreme Day Trips, day-long adventures, unique trips, easy booking, travel community" />
        <meta property="og:title" content="Welcome to Extreme Day Trips" />
        <meta property="og:description" content="Embark on the adventure of a lifetime with Extreme Day Trips. Find your next day-long journey and share your experiences with our explorer community." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://extremedaytrips.com" />
        <meta property="og:image" content="https://extremedaytrips.com/path-to-your-og-image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Welcome to Extreme Day Trips" />
        <meta name="twitter:description" content="Extreme Day Trips offers a platform for unique, day-long adventures. Book easily and connect with fellow adventurers." />
        <meta name="twitter:image" content="https://extremedaytrips.com/path-to-your-twitter-card-image.jpg" />
        {/* Add any additional SEO related tags as needed */}
      </Helmet>
      <Navbar />
      <header className="hero">
        <h1>Welcome to ExtremeDayTrips.com</h1>
        <p>Discover and book the most thrilling 24-hour round trips!</p>
        <button className="cta-button" onClick={navigateToSearch}>Search for day trips now!</button>
      </header>
      {/* Carousel Section */}
     <section className="carousel" ref={carouselRef}>
        {carouselItems.map(item => (
          <div 
            key={item.id} 
            className="carousel-item" 
            style={{ backgroundColor: item.bgColor }}
            onClick={() => navigateToSearchWithParams(item.code)} // Make the div clickable
            role="button" // Make it accessible as a button
            tabIndex={0} // Allows keyboard navigation
          >
            <FontAwesomeIcon icon={faPlane} className="plane-icon" />
            <div className="airport-code">{item.code}</div>
            <p>{item.title}</p>
          </div>
        ))}
      </section>

      {/* Signup Form Section */}
      <section className="signup-form-section">
        <h2>Sign up for alerts</h2>
        <p>Get daytrips from your local airport sent straight to your inbox</p>
        <form className="signup-form" method="POST" data-netlify="true" name="alerts-form" form-name="alerts-form" netlify>
        <input type="hidden" name="form-name" value="alerts-form" />
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={handleEmailChange}
            className="email-input"
            name="email"
            id="email"
          />
          <select value={selectedAirport} onChange={handleAirportChange} className="airport-select" name="airport" id="airport" required>
            <option value="">Select an airport</option>
            {/* Add options here */}
            {carouselItems.map(item => (
              <option key={item.id} value={item.code}>{item.title}</option>
            ))}
          </select>
          <button type="submit" className="signup-submit">Sign Up</button>
        </form>
      </section>

      <section className="download-app-section">
        <h2>Download Our App</h2>
        <div className="app-badges">
          <a href="https://play.google.com/store/apps/details?id=com.extremedaytrips.app&referrer=utm_source%3Dwebsite%26utm_medium%3Dbanner%26utm_campaign%3Dwebsite-main" target="_blank" rel="noopener noreferrer">
            <img src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" alt="Get it on Google Play" style={{ height: '60px' }} />
          </a>
          <a href="https://apps.apple.com/app/apple-store/id6477308980?pt=126413899&ct=website-main&mt=8" target="_blank" rel="noopener noreferrer">
            <img src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg" alt="Download on the App Store" style={{ height: '45px' }} />
          </a>
        </div>
      </section>

      <section className="perks">
        <h2>Why Choose Us?</h2>
        <div className="perk">
          <FontAwesomeIcon icon={faMountain} size="3x" />
          <h3>Unique Adventures</h3>
          <p>Handpicked trips for the ultimate day-long adventure.</p>
        </div>
        <div className="perk">
          <FontAwesomeIcon icon={faTicketAlt} size="3x" />
          <h3>Easy Booking</h3>
          <p>Click through and book easily</p>
        </div>
        <div className="perk">
          <FontAwesomeIcon icon={faUsers} size="3x" />
          <h3>Community Driven</h3>
          <p>Join a community of explorers sharing their experiences.</p>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
