// src/components/Navbar.js
import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { AuthContext } from '../AuthContext'; // Adjust the path as necessary
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/logo_no_bg.png'; // Adjust the path as necessary
import '../styles/Navbar.css'; // Adjust the path as necessary

const Navbar = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);

  const navigateToSearch = () => {
    navigate('/search');
  };

  const handleLogout = async () => {
    try {
      await signOut(getAuth());
      navigate('/welcome');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <nav className="navbar">
      <Link to="/">
        <img src={logo} alt="ExtremeDayTrips Logo" className="logo" />
      </Link>
      <div className="navigation-buttons">
        <a href="http://shop.extremedaytrips.com" className="login-button" style={{ textDecoration: 'none' }}>Shop</a>
        <button onClick={navigateToSearch} className="find-trips-button">Find Trips</button>
      <div className="profile-area">
        {currentUser ? (
          <>
            <button onClick={toggleDropdown} className="profile-icon-button">
              <FontAwesomeIcon icon={faUserCircle} size="2x" />
            </button>
            {showDropdown && (
              <div className="dropdown-menu show">
                <div onClick={handleLogout}>Logout</div>
              </div>
            )}
          </>
        ) : (
          <button onClick={() => navigate('/welcome')} className="login-button">Login</button>
        )}
      </div>
      </div>
    </nav>
  );
};

export default Navbar;
