// src/components/TripCard.js
import React, { useState } from 'react';
import useImageFetcher from '../utilities/ImageFetcher';
import '../styles/TripCard.css';
import { BounceLoader } from 'react-spinners';

const TripCard = ({ tripData }) => {
  const {
    arrivalAirport,
    departureAirport,
    departureDateTime,
    returnDateTime,
    destinationCity,
    arrivalCity,
    flightNumber,
    price,
    bookingToken,
    userUID,
  } = tripData;

  const city = destinationCity || arrivalCity;
  const cityForImage = city ? city.replace(/\s+/g, '-').toLowerCase() : 'default-city';
  const imageUrl = useImageFetcher(cityForImage);
  const [isLoading, setIsLoading] = useState(true);  // New state for tracking image loading

  const formatDateForDeepLink = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  };

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      if (isNaN(date)) throw new Error('Invalid date');
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return date.toLocaleDateString('en-GB', options).replace(/,/g, '');
    } catch (e) {
      console.error('Date conversion error:', e.message);
      return dateString; // Return original string if conversion fails
    }
  };

  const formattedDepartureDateTime = departureDateTime ? formatDate(departureDateTime) : '';
  const formattedReturnDateTime = returnDateTime ? formatDate(returnDateTime) : '';

  const isBookable = departureAirport && arrivalAirport && departureDateTime && !userUID;
  const departureDateForDeepLink = departureDateTime ? formatDateForDeepLink(departureDateTime) : '';
  const returnDateForDeepLink = returnDateTime ? formatDateForDeepLink(returnDateTime) : '';

  const handleBookNowClick = () => {
    if (bookingToken) {
      console.log(`Booking token: ${bookingToken}`);
    } else {
      console.log('No booking token available.');
    }
  };

  // Determine the deep link URL based on whether bookingToken is available
  const getBookingUrl = () => {
    // Check if flight number contains 'fr'
    if (flightNumber.toLowerCase().includes('fr')) {
      return 'https://www.ryanair.com';
    }

    // Existing logic for Kiwi link
    return bookingToken
      ? `https://www.kiwi.com/booking?affilid=airporttransittipsdeeplinkdaytrip&token=${bookingToken}`
      : `https://www.kiwi.com/deep?affilid=airporttransittipsdeeplinkdaytrip&currency=GBP&departure=${departureDateForDeepLink}&destination=${arrivalAirport}&lang=en&origin=${departureAirport}&return=${returnDateForDeepLink}&returnFromDifferentAirport=false&returnToDifferentAirport=false`;
  };

  return (
    <div className="trip-card">
      <div className="trip-image">
        {isLoading && (
          <div className="loader-container"> {/* You may need to style this container */}
            <BounceLoader loading={isLoading} color={"#0056b3"} size={50} />
          </div>
        )}
        <img
          src={imageUrl}
          alt={`View of ${city}`}
          style={{ display: isLoading ? 'none' : 'block' }} // Hide img element while loading
          onLoad={() => setIsLoading(false)}  // Set loading to false when image has loaded
        />
      </div>
      <div className="trip-content">
        {userUID && <div className="user-submitted-pill">User Submitted</div>}
        <div className="trip-info">
          <h3>{city}</h3>
          <p>Departure: {departureAirport} - Arrival: {arrivalAirport}</p>
          <p>Depart: {formattedDepartureDateTime}</p>
          <p>Return: {formattedReturnDateTime}</p>
          <p>Flight Number: {flightNumber}</p>
          <p>Price: £{parseFloat(price).toFixed(2)}</p>
        </div>
        {isBookable && (
        <a
          href={getBookingUrl()}
          className="book-now-button"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleBookNowClick}
        >
          Book Now
        </a>
      )}
      </div>
    </div>
  );
};

export default TripCard;
