// firebaseConfig.js

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; // Import getAuth
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDz31hxjsWzH4KFuLMs7FniKsjCtw_Zr2Y",
  authDomain: "extreme-day-trips.firebaseapp.com",
  projectId: "extreme-day-trips",
  storageBucket: "extreme-day-trips.appspot.com",
  messagingSenderId: "767212907233",
  appId: "1:767212907233:web:5bd6f20b731d06d1f1e5a0",
  measurementId: "G-54Y9WSZ03R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app); // Initialize Firebase Authentication
const db = getFirestore(app);

// Initialize analytics if needed, not exporting since it's not used elsewhere
getAnalytics(app);

// Export the app and auth
export { app, auth, db };