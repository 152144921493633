import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/style.css';
import { AuthContext } from '../AuthContext';
import { Helmet } from 'react-helmet';

// Import Firebase Auth functions
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { app } from '../firebaseConfig';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  const goBack = () => {
    navigate('/');
  };

  const handleLogin = async () => {
    setError('');
    try {
      const auth = getAuth(app);
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (error) {
      setError(`Login failed, please try again!`);
    }
  };

  const navigateToResetPassword = () => {
    navigate('/password-reset');
  };

  return (
    <div className="welcome-container">
    <Helmet>
      <title>Login - Extreme Day Trips</title>
      <meta name="description" content="Log in to your Extreme Day Trips account to access and book unique day-long adventures. Join our community of adventure enthusiasts." />
      <meta name="keywords" content="login, Extreme Day Trips, account access, book adventures, travel community" />
      <meta property="og:title" content="Login - Extreme Day Trips" />
      <meta property="og:description" content="Access your Extreme Day Trips account to manage bookings and connect with a global community of explorers." />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://extremedaytrips.com/login" />
      <meta property="og:image" content="https://extremedaytrips.com/path-to-your-og-image.jpg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Login - Extreme Day Trips" />
      <meta name="twitter:description" content="Log in to Extreme Day Trips and embark on your next day-long adventure. Connect with fellow adventure enthusiasts." />
      <meta name="twitter:image" content="https://extremedaytrips.com/path-to-your-twitter-card-image.jpg" />
      {/* Add any additional SEO related tags as needed */}
    </Helmet>
      <div className="back-arrow" onClick={goBack}>&#x2190;</div>
      <div className="welcome-content">
        <h1>Login to Extreme Day Trips</h1>
        {error && <div className="error-message">{error}</div>}
        <input
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="input-field"
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="input-field"
          required
        />
        <button onClick={handleLogin} className="welcome-button">Login</button>
        <div className="forgot-password" onClick={navigateToResetPassword}>
          Forgot password?
        </div>
      </div>
    </div>
  );
};

export default Login;
