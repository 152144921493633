// src/StepTwo.js
import React, { useRef, useEffect, useState } from 'react';
import useAirportPicker from '../utilities/airportpicker';
import '../styles/style.css';

const StepTwo = ({ prevStep, handleSubmit, handleChange, values }) => {
  const {
    handleAutocomplete,
    departureSuggestions,
    setDepartureSuggestions,
  } = useAirportPicker();
  const [error, setError] = useState('');

  const autocompleteRef = useRef(null);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    handleChange(name)({ target: { value: checked } });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    handleChange(name)({ target: { value } }); // Updated to match the expected structure in handleChange

    if (name === 'airport') {
      handleAutocomplete(value, 'departureAirport');
    }
  };

  const handleAirportSelect = (suggestion) => {
    if (typeof handleChange === 'function') {
      const handleFieldChange = handleChange('airport');
      if (typeof handleFieldChange === 'function') {
        handleFieldChange({ target: { value: suggestion.iata_code } });
      }
    }
    setDepartureSuggestions([]);
  };

   const handleFormSubmit = () => {
    if (!values.terms) { 
      setError('Please accept the terms and conditions to proceed.');
      console.log('Error set:', error); // Add this line to check if error is being set
    } else {
      setError('');
      handleSubmit();
    }
  };

  // Hide suggestions when clicking outside of the component
  useEffect(() => {
    function handleClickOutside(event) {
      if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
        setDepartureSuggestions([]);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [autocompleteRef]);

  return (
    <div className="welcome-content">
      <h1>Sign Up - Step 2</h1>
      {error && <div className="error-message">{error}</div>}
      <input
        type="text"
        placeholder="Location"
        onChange={handleInputChange}
        name="location"
        value={values.location}
        className="input-field"
      />
      <div className="autocomplete" ref={autocompleteRef}>
        <input
          type="text"
          placeholder="Favourite Airport"
          onChange={handleInputChange}
          name="airport"
          value={values.airport}
          className="input-field"
        />
        {departureSuggestions.length > 0 && (
          <ul className="suggestions">
            {departureSuggestions.map((suggestion, index) => (
              <li key={index} onClick={() => handleAirportSelect(suggestion)}>
                {suggestion.name} ({suggestion.iata_code})
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="marketing-consent">
        <input
          type="checkbox"
          id="marketingConsent"
          name="marketingConsent"
          checked={values.marketingConsent}
          onChange={handleCheckboxChange}
          className="checkbox-input"
        />
        <label htmlFor="marketingConsent">I agree to receive marketing communications.</label>
      </div>
      <div className="terms">
        <input
          type="checkbox"
          id="terms"
          name="terms"
          checked={values.terms}
          onChange={handleCheckboxChange}
          className="checkbox-input"
          required
        />
        <label htmlFor="terms">I agree to the <a href="https://extremedaytrips.com/privacy-policy" target="_blank">privacy policy</a> and <a href="https://extremedaytrips.com/terms" target="_blank">terms and conditions</a></label>
      </div>
      <button onClick={prevStep} className="welcome-button">Back</button>
      <button onClick={handleFormSubmit} className="welcome-button">Sign Up</button>
    </div>
  );
};

export default StepTwo;
