import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, orderBy, limit, startAfter } from 'firebase/firestore';
import { db } from './firebaseConfig';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import TripCard from './components/TripCard';
import './styles/Trips.css';
import { Helmet } from 'react-helmet';
import AirportCard from './components/AirportCard';
import { useNavigate } from 'react-router-dom';

const airports = [
  {
    name: "Therme Spa Budapest, Hungary",
    distance: "16 km",
    cost: "From €3",
    description: "Soak in the healing waters of one of Budapest's famed thermal baths for a rejuvenating day trip.",
    image: "./other/therme.jpeg"
  },
  {
    name: "Venice, Italy",
    distance: "8 km",
    cost: "From €20",
    description: "Marvel at the storied canals and stunning architecture. Ideal for a day of exploration and romance."
  },
  {
    name: "Pisa, Italy",
    distance: "2 km",
    cost: "€2 for a bus ride",
    description: "Capture your leaning tower moment and explore the historic sights in a leisurely day."
  },
  {
    name: "Milan, Italy",
    distance: "7 km",
    cost: "From €5",
    description: "Dive into fashion, art, and culture with a quick trip to this vibrant Italian metropolis."
  },
  {
    name: "Brussels, Belgium",
    distance: "11 km",
    cost: "From €7.50",
    description: "Enjoy the heart of European politics, stunning Grand Place, and, of course, delicious chocolates."
  },
  {
    name: "Stockholm, Sweden",
    distance: "40 km",
    cost: "From €30",
    description: "Island-hop and absorb the rich history and modern Scandinavian design on an unforgettable day out."
  },
  {
    name: "Copenhagen, Denmark",
    distance: "8 km",
    cost: "36 DKK (~€5) for a metro ticket",
    description: "Experience Danish hygge with pastries, design, and waterfront vibes in just one day."
  },
  {
    name: "Dublin, Ireland",
    distance: "10 km",
    cost: "€6 for a bus ride",
    description: "Savor a pint of Guinness and delve into the literary history of this lively capital."
  },
  {
    name: "Belfast, Northern Ireland",
    distance: "5 km",
    cost: "£2.50 for a bus ride",
    description: "Explore the Titanic museum and the historic city center on a quick trip across the Irish Sea."
  },
  {
    name: "Billund, Denmark",
    distance: "3 km",
    cost: "Free shuttle bus",
    description: "Enter the playful world of LEGO at the original LEGOLAND. Perfect for families and fans of all ages.",
    image: "./other/billund.jpg"
  },
  {
    name: "Innsbruck, Austria",
    distance: "4 km",
    cost: "€3 for a bus ride",
    description: "Surrounded by stunning Alps, ideal for hiking or skiing, depending on the season. A mountainous retreat."
  },
  {
    name: "Nice, France",
    distance: "7 km",
    cost: "€6 for a bus ride",
    description: "Bask in the Mediterranean sun and take a stroll along the Promenade des Anglais for a refreshing day."
  },
  {
    name: "Ibiza, Spain",
    distance: "7 km",
    cost: "From €15",
    description: "Not just for parties; explore hidden coves and serene landscapes for a peaceful yet vibrant day trip."
  },
  {
    name: "Faro, Portugal",
    distance: "7 km",
    cost: "€2.50 for a bus ride",
    description: "Gateway to the stunning Algarve coast, offering beautiful beaches and charming towns."
  },
  {
    name: "Malta",
    distance: "8 km",
    cost: "From €10",
    description: "Dive into history and azure waters on this Mediterranean gem, perfect for a cultural and relaxing day.",
    image: "./other/malta.jpg"
  },
];


// Function to extract numeric distance value from the distance string.
const getNumericDistance = (distanceStr) => {
  const distanceParts = distanceStr.split(' ');
  return parseFloat(distanceParts[0]);
};

// Sort airports by distance.
const sortedAirports = airports.sort((a, b) => {
  return getNumericDistance(a.distance) - getNumericDistance(b.distance);
});

const AirportInfo = () => (
    <div className="airport-info-container">
        {sortedAirports.map((airport, index) => (
            <AirportCard key={index} airport={airport} />
        ))}
    </div>
);

const Trips = () => {

const navigate = useNavigate();

const navigateToSearch = () => {
  navigate('/search');
};

return (
    <div className="app-container">
      <Helmet>
        {/* Helmet content */}
      </Helmet>
      <Navbar />
      <header className="hero">
        <h1>Some of the trips you can go on</h1>
        <p>Some of the destinations where you can fly from the UK and return the same day</p>
        <button className="search-now-btn" onClick={navigateToSearch}>Search Now</button>
      </header>
      <section className="content">
        <AirportInfo />
      </section>
      <Footer />
    </div>
  );
};

export default Trips;
