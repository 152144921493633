// src/components/AirportCard.js
import React, { useState, useEffect } from 'react';
import '../styles/AirportCard.css';
import useImageFetcher from '../utilities/ImageFetcher'; // Ensure this path is correct
import { BounceLoader } from 'react-spinners';

const AirportCard = ({ airport }) => {
  const [isLoading, setIsLoading] = useState(true);
  const {
    name,
    distance,
    cost,
    description,
    image: manualImageUrl
  } = airport;

  // Extract city name for image fetching, assuming the city name is the first word.
  const cityName = name.split(',')[0];
  const cityForImage = cityName.replace(/\s+/g, '-').toLowerCase();

  // Always call useImageFetcher
  const fetchedImageUrl = useImageFetcher(cityForImage);
  
  // Determine which image URL to use: manual override or fetched
  const imageUrl = manualImageUrl || fetchedImageUrl;

  useEffect(() => {
    // If there's a manual image URL, we don't need to wait for loading
    if (manualImageUrl) {
      setIsLoading(false);
    }
  }, [manualImageUrl]);

  return (
    <div className="airport-card">
      <div className="airport-image">
        {isLoading && (
          <div className="loader-container">
            <BounceLoader color={"#0056b3"} loading={isLoading} size={60} />
          </div>
        )}
        <img
          src={imageUrl}
          alt={`View of ${cityName}`}
          onLoad={() => setIsLoading(false)}
          style={{ display: isLoading ? 'none' : 'block' }}
        />
      </div>
      <div className="airport-content">
        <div className="airport-info">
          <h3>{name}</h3>
          <p>Distance from City Center: {distance}</p>
          <p>Travel Cost: {cost}</p>
          <p className="description">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default AirportCard;
