import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, orderBy, limit, startAfter } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../styles/Trips.css';
import { Helmet } from 'react-helmet';
import AirportCard from '../components/AirportCard'; // Note: You might consider renaming this component to something like DestinationCard for clarity
import { useNavigate } from 'react-router-dom';

const spaDestinations = [
    {
      name: "Therme Bucharest, Romania",
      distance: "10 km",
      cost: "From €15",
      description: "Europe's largest relaxation and entertainment center, located in the natural light of Bucharest.",
      image: "./other/therme_bucharest.jpg"
    },
    {
      name: "Saturnia Hot Springs, Italy",
      distance: "3 km",
      cost: "Free",
      description: "Natural thermal springs with soothing warm waters, surrounded by the beautiful Tuscan landscape.",
      image: "./other/saturnia.jpg"
    },
    {
      name: "Therme Spa Budapest, Hungary",
      distance: "16 km",
      cost: "From €3",
      description: "Soak in the healing waters of one of Budapest's famed thermal baths for a rejuvenating day trip.",
      image: "./other/therme.jpeg"
    },
    {
      name: "FortySeven Spa, Switzerland",
      distance: "5 km",
      cost: "From €50",
      description: "Luxury wellness retreat offering panoramic views of the Swiss Alps and world-class spa treatments.",
      image: "./other/fortyseven_switzerland.jpg"
    },
    {
      name: "Blue Lagoon, Iceland",
      distance: "20 km",
      cost: "From €50",
      description: "Iconic geothermal spa offering a unique experience in Iceland's otherworldly landscapes.",
      image: "./other/blue_lagoon.jpg"
    },
    {
      name: "Széchenyi Bath, Hungary",
      distance: "2 km",
      cost: "From €20",
      description: "One of the largest thermal spring baths in Europe, offering numerous indoor and outdoor pools.",
      image: "./other/szechenyi_bath.jpg" // Placeholder path, replace with actual
    },
    {
      name: "Rudas Bath, Hungary",
      distance: "1 km",
      cost: "From €10",
      description: "Historic Turkish bathhouse, dating back to the 16th century, known for its thermal baths and panoramic views.",
      image: "./other/rudas_bath.jpg" // Placeholder path, replace with actual
    },
    {
      name: "Les Bains de Lavey, Switzerland",
      distance: "3 km",
      cost: "From €30",
      description: "Home to Switzerland's hottest thermal spring, this spa offers relaxation with spectacular Alpine scenery.",
      image: "./other/les_bains_de_lavey.jpg" // Placeholder path, replace with actual
    },
    {
      name: "Uniejów Spa, Poland",
      distance: "1 km",
      cost: "From €15",
      description: "A modern thermal spa complex using geothermal waters, with facilities for relaxation and rehabilitation.",
      image: "./other/uniejow_spa.jpg" // Placeholder path, replace with actual
    },
    // Add more spa destinations as needed...
  ];
  

// Assuming distance extraction function remains the same
const getNumericDistance = (distanceStr) => {
  const distanceParts = distanceStr.split(' ');
  return parseFloat(distanceParts[0]);
};

const sortedSpaDestinations = spaDestinations.sort((a, b) => {
  return getNumericDistance(a.distance) - getNumericDistance(b.distance);
});

const SpaInfo = () => (
    <div className="spa-info-container">
        {sortedSpaDestinations.map((spa, index) => (
            <AirportCard key={index} airport={spa} /> // Consider renaming AirportCard
        ))}
    </div>
);

const SpaBreaks = () => {

const navigate = useNavigate();

const navigateToSearch = () => {
  navigate('/search');
};

return (
    <div className="app-container">
      <Helmet>
        {/* Helmet content */}
      </Helmet>
      <Navbar />
      <header className="hero">
        <h1>Explore Europe's Best Spa Breaks</h1>
        <p>Discover relaxing spa destinations easily reachable from the UK for your next day trip or weekend getaway.</p>
        <button className="search-now-btn" onClick={navigateToSearch}>Search Now</button>
      </header>
      <section className="content">
        <SpaInfo />
      </section>
      <Footer />
    </div>
  );
};

export default SpaBreaks;
