// src/utilities/dateHelpers.js
export const getUpcomingWeekends = () => {
  const weekends = [];
  const nextMonth = new Date();
  nextMonth.setMonth(nextMonth.getMonth() + 1); // Move to next month
  nextMonth.setDate(1); // Start at the beginning of the next month

  const endOfNextMonth = new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 0); // End of the next month

  while (nextMonth <= endOfNextMonth) {
    if (nextMonth.getDay() === 6) { // Check for Saturday (6)
      const sunday = new Date(nextMonth.getTime());
      sunday.setDate(sunday.getDate() + 1);

      // Format dates to YYYY-MM-DD and add to the array
      const saturdayStr = nextMonth.toISOString().split('T')[0];
      weekends.push(saturdayStr);
      // Check if Sunday is within the month
      if (sunday.getMonth() === nextMonth.getMonth()) {
        const sundayStr = sunday.toISOString().split('T')[0];
        weekends.push(sundayStr);
      }

      // Skip to next Saturday
      nextMonth.setDate(nextMonth.getDate() + 7);
    } else {
      // Move to the next day and check again
      nextMonth.setDate(nextMonth.getDate() + 1);
    }
  }

  return weekends;
};