// src/utilities/airportpicker.js
import { useState, useEffect } from 'react';

const useAirportPicker = () => {
  const [airports, setAirports] = useState([]);
  const [departureSuggestions, setDepartureSuggestions] = useState([]);
  const [arrivalSuggestions, setArrivalSuggestions] = useState([]);

  useEffect(() => {
    const fetchAirports = async () => {
      const res = await fetch("https://raw.githubusercontent.com/algolia/datasets/master/airports/airports.json");
      const data = await res.json();
      setAirports(data);
    };

    fetchAirports();
  }, []);

  const handleAutocomplete = (searchText, field) => {
    if (searchText.length === 0) {
      field === 'departureAirport' ? setDepartureSuggestions([]) : setArrivalSuggestions([]);
      return;
    }
    const regex = new RegExp(`^${searchText}`, "gi");
    const matches = airports.filter(airport =>
      airport.country.match(regex) ||
      airport.name.match(regex) ||
      airport.city.match(regex) ||
      airport.iata_code.match(regex)
    );
    field === 'departureAirport' ? setDepartureSuggestions(matches) : setArrivalSuggestions(matches);
  };

  return {
    handleAutocomplete,
    departureSuggestions,
    setDepartureSuggestions,
    arrivalSuggestions,
    setArrivalSuggestions,
  };
};

export default useAirportPicker;
