// src/utilities/useImageFetcher.js
import { useState, useEffect } from 'react';

const useImageFetcher = (city) => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const localCities = [
      'alicante', 'amsterdam', 'barcelona', 'belfast', 'berlin', 'bilbao',
      'bremen', 'bristol', 'brussels', 'budapest', 'cork', 'dublin',
      'edinburgh', 'geneva', 'glasgow', 'helsinki', 'innsbruck', 'knock',
      'lisbon', 'london', 'madrid', 'marseille', 'milan', 'nuremberg',
      'olbia', 'palma', 'paris', 'pisa', 'porto', 'riga', 'rome', 'rotterdam',
      'salzburg', 'shannon', 'tallinn', 'tirana', 'vilnius', 'krakow', 'bratislava', 'gibraltar', 'copenhagen', 'marrakech', 'frankfurt', 'málaga', 'nice', 'birmingham', 'lanzarote', 'manchester', 'nürnberg', 'leipzig', 'cologne', 'prague', 'warsaw', 'vienna', 'zurich', 'seville', 'venice', 'bologna', 'faro', 'naples', 'corfu', 'santander', 'oslo', 'larnaca', 'paphos', 'bucharest', 'gothenburg', 'stockholm',
      'las palmas', 'southampton', 'tenerife', 'cluj-napoca', 'inverness', 'saint helier', 'baden baden',
      'newcastle', 'marrakesh', 'lyon', 'reus', 'toulouse', 'santiago de compostela',
      'verona', 'luxembourg', 'trieste', 'torino', 'prague', 'bergerac',
      'charlestown', 'killarney', 'ibiza', 'dortmund', 'palma, majorca', 'eindhoven'
    ];


    const fetchImage = async () => {
      if (localCities.includes(city.toLowerCase())) {
        // Use local image if the city matches one of the local image filenames
        setImageUrl(`cities/${city.toLowerCase()}.jpg`);
      } else {
        try {
          // Fetching image using Teleport API
          const response = await fetch(`https://api.teleport.org/api/urban_areas/slug:${city.toLowerCase()}/images/`);
          const data = await response.json();

          if (data.photos && data.photos.length > 0) {
            setImageUrl(data.photos[0].image.web); // Using the web resolution image
          } else {
            throw new Error('Image URL not found');
          }
        } catch (error) {
          console.error('Failed to fetch image:', error);
          setImageUrl('default.jpg'); // Set a default image path
        }
      }
    };

    if (city && city !== 'default-city') {
      fetchImage();
    } else {
      setImageUrl('default.jpg'); // Set a default image path
    }
  }, [city]);

  return imageUrl;
};

export default useImageFetcher;
