// src/Signup.js

import React, { useState, useContext, useEffect } from 'react';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import { createUser } from './SignupProcessor';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext'; // Import AuthContext
import '../styles/style.css'; 
import { Helmet } from 'react-helmet';

const Signup = () => {
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate(); // Hook for navigation
  const { currentUser } = useContext(AuthContext); // Access current user from context
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    airport: '',
    location: '',
    countryCode: '+44',
    marketingConsent: false,
    terms: false
  });

  useEffect(() => {
    if (currentUser) {
      navigate('/search'); // Navigate to chat if there's a logged-in user
    }
  }, [currentUser, navigate]); // Depend on currentUser and navigate

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = input => e => {
    setFormData({ ...formData, [input]: e.target.value });
  };

  const goBack = () => {
    navigate('/'); // Navigate to the root route
  };

  const handleSubmit = async () => {
    setIsSubmitting(true); // Start submitting process
    try {
      if (!Object.values(formData).every(value => typeof value === 'string' ? value.trim() !== '' : true)) {
        throw new Error('Please fill out all fields.'); // Use Error to maintain consistent error handling
      }
      // Concatenate the country code and phone number
      const fullPhoneNumber = `${formData.countryCode}${formData.phone}`;

      // Include marketingConsent in the user details
      await createUser(formData.email, formData.password, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        phone: fullPhoneNumber,
        email: formData.email,
        location: formData.location,
        airport: formData.airport,
        marketingConsent: formData.marketingConsent,
        terms: formData.terms
      });
      navigate('/'); // Navigate to home after successful sign up
    } catch (error) {
      console.error('Signup error:', error);
      alert(error.message); // Notify user of the error
    } finally {
      setIsSubmitting(false); // End submitting process regardless of outcome
    }
  };

  if (isSubmitting) {
    return (
      <div className="welcome-container">
        <div className="welcome-content">
          <h1>Creating your account...</h1>
          {/* Optionally add more content or a loading spinner here */}
        </div>
      </div>
    );
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return <StepOne nextStep={nextStep} handleChange={handleChange} values={formData} />;
      case 2:
        return <StepTwo prevStep={prevStep} handleChange={handleChange} handleSubmit={handleSubmit} values={formData} />;
      default:
        return <div>Step {step}</div>;
    }
  };

  return (
    <div className="welcome-container">
      <Helmet>
        <title>Sign Up - Extreme Day Trips</title>
        <meta name="description" content="Join Extreme Day Trips by signing up. Create an account to access and book unique adventure trips and connect with other enthusiasts." />
        <meta name="keywords" content="sign up, Extreme Day Trips, create account, adventure trips, travel community" />
        <meta property="og:title" content="Sign Up - Extreme Day Trips" />
        <meta property="og:description" content="Start your journey with Extreme Day Trips. Sign up to discover and book the most exciting day-long trips around the world." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://extremedaytrips.com/signup" />
        <meta property="og:image" content="https://extremedaytrips.com/path-to-your-og-image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Sign Up - Extreme Day Trips" />
        <meta name="twitter:description" content="Create your account at Extreme Day Trips and embark on thrilling adventures. Sign up now to join our community of explorers." />
        <meta name="twitter:image" content="https://extremedaytrips.com/path-to-your-twitter-card-image.jpg" />
        {/* Add any additional SEO related tags as needed */}
      </Helmet>
      <div className="back-arrow" onClick={goBack}>&#x2190;</div>
      {renderStep()}
    </div>
  );
};

export default Signup;
