// src/About.js
import React from 'react';
import './styles/Home.css'; // Reusing Home.css for styling as directed
import Navbar from './components/Navbar'; // Ensure the path is correct
import Footer from './components/Footer'; // Ensure the path is correct
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faGlobeAmericas, faHandsHelping } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

const About = () => {
  return (
    <div className="app-container">
      <Helmet>
        <title>About Us - Extreme Day Trips</title>
        <meta name="description" content="Discover the story behind Extreme Day Trips, our mission, global reach, and commitment to the adventure community. Learn more about what drives us." />
        <meta name="keywords" content="about, Extreme Day Trips, adventure travel, global experiences, mission, community" />
        <meta property="og:title" content="About Us - Extreme Day Trips" />
        <meta property="og:description" content="Learn about Extreme Day Trips' dedication to delivering exceptional day trip adventures worldwide and our commitment to the community of adventure enthusiasts." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://extremedaytrips.com/about" />
        <meta property="og:image" content="https://extremedaytrips.com/path-to-your-og-image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Us - Extreme Day Trips" />
        <meta name="twitter:description" content="Explore the passion and commitment behind Extreme Day Trips, from our global adventures to our community-focused initiatives. Get to know us better." />
        <meta name="twitter:image" content="https://extremedaytrips.com/path-to-your-twitter-card-image.jpg" />
        {/* Add any additional SEO related tags as needed */}
      </Helmet>
      <Navbar />
      <header className="hero">
        <h1>About ExtremeDayTrips.com</h1>
        <p>Learn more about our passion for adventure and commitment to customer satisfaction.</p>
      </header>
      <section className="perks">
        <h2>Our Story</h2>
        <div className="perk">
          <FontAwesomeIcon icon={faInfoCircle} size="3x" />
          <h3>Who We Are</h3>
          <p>We're adventurers at heart, dedicated to providing unforgettable day trips.</p>
        </div>
        <div className="perk">
          <FontAwesomeIcon icon={faGlobeAmericas} size="3x" />
          <h3>Global Reach</h3>
          <p>Spanning continents to bring you the best day trip experiences worldwide.</p>
        </div>
        <div className="perk">
          <FontAwesomeIcon icon={faHandsHelping} size="3x" />
          <h3>Community Focus</h3>
          <p>Building a community of thrill-seekers who share your passion for adventure.</p>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default About;
